/* Change Autocomplete styles in Chrome, Safari &  */

@-webkit-keyframes autofill {
    0%, 100% {
        color: #FFFFFF !important;
        background: transparent !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color: #959595 !important;
    transition: background-color 5000000s ease-in-out 0s !important;
    background: transparent !important;
    -webkit-text-fill-color: #959595 !important;
    -webkit-animation-delay: 1s !important; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill !important;
    -webkit-animation-fill-mode: both !important;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: white;
}

.Cookie--gfp-theme {
    background: var(--v-secondary-darken1);
    color: var(--v-secondary-lighten5);
    padding: 1em
}

.Cookie--gfp-theme a {
    text-decoration: none;
    color: var(--v-info-darken3);
}

.Cookie--gfp-theme a:hover {
    text-decoration: underline;
}

.Cookie--gfp-theme > div.Cookie__buttons > button.Cookie__button {
    background: var(--v-info-darken3);
    padding: 0.625em 3.125em;
    color: var(--v-info-lighten5);
    border-radius: 0;
    border: 0;
    font-size: 1em;
    font-weight: bold;
    border-radius: 6px;
}

.Cookie--gfp-theme > div.Cookie__buttons > button.Cookie__button:hover {
    background: var(--v-info-darken2);
}

/* Swiper button Override */

.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--v-brand-primary-base));
    background-color: var(--v-brand-primary-lighten5);
    border-radius: 50%;
}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 14px;
    content: unset;
}

/* Default Vuetify Overrides */

th[role="columnheader"] {
    white-space: nowrap;
}

.text-no-select {
    user-select: none;
}

.text-transform-none {
    text-transform: none;
}

.fill-width {
    width: 100%;
}
